import { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Requests from "../utils/requests";
import axios from "axios";
import { config, server } from "../env";
import Alert from "../components/Alert";
import Loader from "../components/Loader";
import StatsCard from "../components/StatsCard";
import { Badge } from "react-bootstrap";
import moment from "moment/moment";
import BarChart from "../components/BarChart";

const request = new Requests();

const Stats = () => {
  const [incomings, setIncomings] = useState({});
  const [loaders, setLoaders] = useState(false);
  const [allStats, setAllStats] = useState({});
  const [recentOrders, setRecentOrders] = useState([]);
  const [loader, setLoader] = useState("");
  const [alert, setAlert] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    fetchIncomings();
    recentOrder();
    fetchAllStats();
    getDepositsBarChart();
    getOrdersBarChart();
  }, []);
  // barchart data
  const getOrdersBarChart = async () => {
    await axios
      .get(server + "/v1/admin/read-orders-chart", config)
      .then((res) => {
        const e = document.getElementById("order_chart");
        var canvas = document.createElement("CANVAS");
        while (e.firstChild) {
          e.removeChild(e.firstChild);
        }
        e.appendChild(canvas);
        BarChart(canvas, res.data, "blue", "Orders");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepositsBarChart = async () => {
    await axios
      .get(server + "/v1/admin/read-invoices-chart", config)
      .then((res) => {
        const e = document.getElementById("invoice_chart");
        var canvas = document.createElement("CANVAS");
        while (e.firstChild) {
          e.removeChild(e.firstChild);
        }
        e.appendChild(canvas);
        BarChart(canvas, res.data, "green", "Deposits");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchIncomings = async () => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + "/v1/admin/dashboard/readStats", config)
      .then((res) => {
        setIncomings(res.data);
        setAlert(<Alert className="success" message={res.data.message} />);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setAlert(
          <Alert className="danger" message={err.response?.data?.message} />
        );
        setLoader("");
        setLoaders(false);
      });
  };

  const fetchAllStats = async () => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + "/v1/admin/all-stats", config)
      .then((res) => {
        setAllStats(res.data);
        setAlert(<Alert className="success" message={res.data.message} />);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setAlert(
          <Alert className="danger" message={err.response?.data?.message} />
        );
        setLoader("");
        setLoaders(false);
      });
  };

  const recentOrder = async () => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + "/v1/admin/recent-orders", config)
      .then((res) => {
        setRecentOrders(res.data.orders);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Stats" />
      <div className="row">
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <StatsCard
              title={`Total Users`}
              value={`${incomings.totalUsers ?? 0}`}
              icon={"bi bi-diagram-2 nav-icon"}
            />
          )}
        </div>
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <StatsCard
              title={`Total Spends`}
              value={`$${Number(incomings?.netRevenue).toFixed(2) ?? 0}`}
              icon={"bi-currency-dollar"}
            />
          )}
        </div>
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <StatsCard
              title={`Total Orders`}
              value={`${incomings.totalOrders ?? 0}`}
              icon={"bi bi-calculator nav-icon"}
            />
          )}
        </div>
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <StatsCard
              title={`Total User Balance`}
              value={`$${Number(incomings?.totalBalance).toFixed(2) ?? 0}`}
              icon={"   bi bi-coin"}
            />
          )}
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12 mb-3">
          <div className="card ">
         
            <div className="card-body row">
              <div className="col-1 d-flex align-items-center justify-content-center">
                <h3 className=" text-center">Orders :</h3>
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">TODAY</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {allStats?.orders?.today ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cart-check-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">WEEK</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {allStats?.orders?.week ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cart-check-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">MONTH</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {allStats?.orders?.month ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cart-check-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">ALLTIME</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {allStats?.orders?.allTime ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cart-check-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
            </div>
         
            <div className="card-body row">
              <div className="col-1 d-flex align-items-center justify-content-center">
                <h3 className=" text-center">Revenue:</h3>
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">TODAY</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            ${allStats?.revenue?.today ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-coin"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">WEEK</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            ${allStats?.revenue?.week ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-coin"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">MONTH</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            $ {allStats?.revenue?.month ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-coin"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">ALLTIME</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            ${allStats?.revenue?.allTime ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-coin"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
            </div>
            <div className="card-body row">
              <div className="col-1 d-flex align-items-center justify-content-center">
                <h3 className=" text-center">Users :</h3>
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">TODAY</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {allStats?.users?.today ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-people-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">WEEK</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {allStats?.users?.week ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-people-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">MONTH</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {allStats?.users?.month ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-people-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">ALLTIME</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            {allStats?.users?.allTime ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-people-fill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
            </div>
            <div className="card-body row">
              <div className="col-1 d-flex align-items-center justify-content-center">
                <h3 className=" text-center">Deposits:</h3>
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">TODAY</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            ${allStats?.deposits?.today ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cash-coin"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">WEEK</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            $ {allStats?.deposits?.week ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cash-coin"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">MONTH</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            ${allStats?.deposits?.month ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cash-coin"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
              <div className="col ">
                {loaders ? (
                  loader
                ) : (
                  <span
                    className="card card-hover-shadow h-100"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className="card-body">
                      <h6 className="card-subtitle">ALLTIME</h6>
                      <div className="row align-items-center gx-2 mb-1">
                        <div className="col-6">
                          <h2 className="card-title text-inherit">
                            $ {allStats?.deposits?.allTime ?? 0}
                          </h2>
                        </div>
                        <div className="col-6">
                          <div
                            className="chartjs-custom"
                            style={{ height: "3rem" }}
                          >
                            <div className="w-100 text-end display-4">
                              <i class="bi bi-cash-coin"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-6 mb-3">
          <div className="card card-bordered h-100">
            <div className="card-header">
              <h5 className="card-title">Orders Graph</h5>
            </div>
            <div className="card-body">
              <div id="order_chart"></div>
            </div>
          </div>
        </div>
        <div className="col-6 mb-3">
          <div className="card card-bordered h-100">
            <div className="card-header">
              <h5 className="card-title">Total Deposits</h5>
            </div>
            <div className="card-body">
              <div id="invoice_chart"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body px-0 pb-0">
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="thead-light ">
              <tr>
                <th scope="col">Order ID</th>
                <th scope="col">Username</th>
                <th scope="col">From</th>
                <th scope="col">To</th>
                <th scope="col">LabelType</th>
                <th scope="col">Tracking</th>
                <th scope="col">Price</th>
                <th scope="col">Status</th>
                <th scope="col">Created At</th>
                {/* <th scope="col">Action</th> */}
              </tr>
            </thead>

            <tbody>
              {recentOrders.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center">
                    <span className="text-center">No Orders</span>
                  </td>
                </tr>
              ) : (
                recentOrders?.map((product) => (
                  <tr key={product._id}>
                    <td>{product._id}</td>
                    <td>{product.user?.username}</td>
                    <td>{product.FromName}</td>
                    <td>{product.ToName}</td>
                    <td>{product.labelType?.name}</td>
                    <td>{product.tracking}</td>
                    <td>${product.price}</td>
                    <td>
                      <h4>
                        {product.status === "paid" && (
                          <Badge bg="warning">Paid</Badge>
                        )}
                        {product.status === "completed" && (
                          <Badge bg="success">Completed</Badge>
                        )}
                        {product.status === "cancelled" && (
                          <Badge bg="danger">Cancelled</Badge>
                        )}

                        {product.status === "pending" && (
                          <Badge bg="warning">Pending</Badge>
                        )}
                      </h4>
                    </td>
                    <td>
                      {" "}
                      {moment(new Date(product.createdAt)).format(
                        "M/D/YYYY, h:mm:ss A"
                      )}
                    </td>
                    {/* <td>
                          <div className="d-flex gap-2">
                            <button
                              className="btn btn-sm btn-info mr-2"
                              onClick={() => {
                                setOrder(product);
                                onOpenOrderDetailsModal();
                              }}
                            >
                              <i className="bi-eye"></i>
                            </button>
                          </div>
                        </td> */}
                  </tr>
                ))
              )}
              {loaders && (
                <tr>
                  <td colSpan="12" className="text-center">
                    <div className="text-center">
                      <Loader />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Stats;
