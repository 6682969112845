import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { config, server } from "../env";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

const SinglePricing = () => {
  const { id } = useParams();
  const [pricing, setPricing] = useState({});
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [singleWeight, setSingleWeight] = useState({});
  const navigate = useNavigate();

  const [showAddPricing, setShowAddPricing] = useState(false);

  const onCloseAddPricingModal = () => {
    setShowAddPricing(false);
  };
  const onOpenAddPricingModal = () => setShowAddPricing(true);

  const [showEditPricing, setShowEditPricing] = useState(false);

  const onCloseEditPricingModal = () => {
    setShowEditPricing(false);
  };
  const onOpenEditPricingModal = () => setShowEditPricing(true);

  const getPricing = async () => {
    await axios
      .get(server + "/v1/labeltype/read/" + id, config)
      .then((res) => {
        setPricing(res.data.labelType);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getPricing();
  }, [id]);

  const addWeight = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      fromWeight: e.target.fromWeight.value,
      toWeight: e.target.toWeight.value,
      price: e.target.price.value,
      premiumPrice: e.target.premium.value,
      originalPrice: e.target.originalPrice.value,
    };

    await axios
      .post(server + "/v1/labeltype/addweight/" + id, data, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        getPricing();
        onCloseAddPricingModal();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const deleteWeight = async (fromWeight, toWeight) => {
    const data = {
      fromWeight: fromWeight,
      toWeight: toWeight,
    };

    await axios
      .post(server + "/v1/labeltype/deleteWeight/" + id, data, config)
      .then((res) => {
        toast.success(res.data.message);
        getPricing();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const updateWeight = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      price_id: singleWeight._id,
      fromWeight: e.target.fromWeight.value,
      toWeight: e.target.toWeight.value,
      price: e.target.price.value,
      premiumPrice: e.target.premium.value,
      originalPrice: e.target.originalPrice.value,
    };

    await axios
      .put(server + "/v1/labeltype/updateWeight/" + id, data, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        getPricing();
        onCloseEditPricingModal();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };
  return (
    <div className="content container-fluid">
      <Header title="Pricing Weight" />
      <div className="nk-content">
        <div className="">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content  w-100">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div className="d-flex gap-2 text-center align-items-center">
                        {/* <Link to="/labelPricing" className="btn btn-sm btn-warning">
                                                    <i className="icon ni ni-arrow-left"></i> Go Back
                                                </Link> */}
                        <Button
                          className="btn btn-warning"
                          onClick={() => navigate("/pricing")}
                        >
                          <i class="bi bi-arrow-left"></i>
                          Go Back
                        </Button>
                        <h2 className="card-title">{pricing.name}</h2>
                      </div>
                      <button
                        className="btn btn-info"
                        data-toggle="modal"
                        data-target="#create"
                        onClick={onOpenAddPricingModal}
                      >
                        <i className="mdi mdi-note-plus"></i> Add Weight
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body px-0 pb-0">
          <div class="table-responsive">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>#</th>
                  <th>From Weight</th>
                  <th>To Weight</th>
                  <th>Original Price</th>
                  <th>Price</th>
                  <th>Premium Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pricing.prices?.map((price, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{price.fromWeight}</td>
                    <td>{price.toWeight}</td>
                    <td>${price.originalPrice ?? 0.0}</td>
                    <td>${price.price}</td>
                    <td>${price.premiumPrice}</td>
                    <td>
                      <div className="d-flex gap-2">
                        <button
                          className="btn btn-primary mr-2"
                          data-toggle="modal"
                          data-target="#edit"
                          onClick={() => {
                            setSingleWeight(price);
                            onOpenEditPricingModal();
                          }}
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() =>
                            deleteWeight(price.fromWeight, price.toWeight)
                          }
                        >
                          <i class="bi bi-trash-fill"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* create Pricing */}

      <Modal
        show={showAddPricing}
        onHide={onCloseAddPricingModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3> Add weight </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
          <form onSubmit={addWeight}>
            <div className="form-group mb-3">
              <label>From Weight</label>
              <input
                type="number"
                className="form-control"
                name="fromWeight"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>To Weight</label>
              <input
                type="number"
                className="form-control"
                name="toWeight"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Original Price</label>
              <input
                type="tel"
                className="form-control"
                name="originalPrice"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Price</label>
              <input
                type="tel"
                className="form-control"
                name="price"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Premium Price</label>
              <input
                type="tel"
                className="form-control"
                name="premium"
                required
              />
            </div>
            <div className="d-flex mt-2 justify-content-end ">
              <button
                type="button"
                className="btn btn-white me-3"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Add {loader}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Edit Pricing */}
      <Modal
        show={showEditPricing}
        onHide={onCloseEditPricingModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3> Edit weight </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
          <form onSubmit={updateWeight}>
            <div className="form-group mb-3">
              <label>From Weight</label>
              <input
                type="number"
                className="form-control"
                name="fromWeight"
                required
                defaultValue={singleWeight.fromWeight}
              />
            </div>
            <div className="form-group mb-3">
              <label>To Weight</label>
              <input
                type="number"
                className="form-control"
                name="toWeight"
                required
                defaultValue={singleWeight.toWeight}
              />
            </div>
            <div className="form-group mb-3">
              <label>Original Price</label>
              <input
                type="tel"
                className="form-control"
                name="originalPrice"
                required
                defaultValue={singleWeight.originalPrice}
              />
            </div>
            <div className="form-group mb-3">
              <label>Price</label>
              <input
                type="tel"
                className="form-control"
                name="price"
                defaultValue={singleWeight.price}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Premium Price</label>
              <input
                type="tel"
                className="form-control"
                name="premium"
                required
                defaultValue={singleWeight.premiumPrice}
              />
            </div>
            <div className="d-flex mt-2 justify-content-end ">
              <button
                type="button"
                className="btn btn-white me-3"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Update {loader}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SinglePricing;
