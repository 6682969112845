import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Loader from "../components/Loader";
import axios from "axios";
import { config, server } from "../env";
import { Badge, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ViewDetails from "./OrderDetails";
import { toast } from "react-toastify";
const Cookies = require("js-cookie");
const AllOrders = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState("");
  const [alert, setAlert] = useState("");
  const [loaders, setLoaders] = useState(false);
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/v1/admin/orders/read/");
  const [order, setOrder] = useState({});
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [singleOrder, setSingleOrder] = useState({});
  const onCloseOrderDetailsModal = () => {
    setShowOrderDetails(false);
  };
  const onOpenOrderDetailsModal = () => setShowOrderDetails(true);

  const [show, setShow] = useState(false);
  const onCloseModal = () => {
    setShow(false);
  };
  const onOpenModal = () => setShow(true);
  useEffect(() => {
    fetchIncomings();
  }, [page, limit]);

  const fetchIncomings = async (search) => {
    var link = "";
    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
    }
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + link, config)
      .then((res) => {
        setProducts(res.data.orders);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };

  const downloadPdf = async (id, toname) => {
    await axios
      .get(`${server}/v1/admin/download/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + Cookies.get("footprint"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${toname}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response) {
        }
      });
  };

  const deleteOrder = async (id) => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .delete(server + "/v1/admin/orders/delete/" + id, config)
      .then((res) => {
        fetchIncomings(res.data);
        toast.success(res.data.message);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);

        setLoader("");
        setLoaders(false);
      });
  };
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="product" />

      <>
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">All Orders</h5>
              <div className="btn-group align-items-center gap-2">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    maxWidth: "200px",
                  }}
                  placeholder="Search..."
                  onChange={(e) => {
                    fetchIncomings(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">Username</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">LabelType</th>
                    <th scope="col">Tracking</th>
                    <th scope="col">Price</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {products.length === 0 ? (
                    <tr>
                      <td colSpan="12" className="text-center">
                        <span className="text-center">No Orders</span>
                      </td>
                    </tr>
                  ) : (
                    products?.map((product) => (
                      <tr key={product._id}>
                        <td>{product._id}</td>
                        <td>{product.user?.username}</td>
                        <td>{product.FromName}</td>
                        <td>{product.ToName}</td>
                        <td>{product.labelType?.name}</td>
                        <td>{product.tracking}</td>
                        <td>${product.price}</td>
                        <td>
                          <h4>
                            {product.status === "paid" && (
                              <Badge bg="warning">Paid</Badge>
                            )}
                            {product.status === "completed" && (
                              <Badge bg="success">Completed</Badge>
                            )}
                            {product.status === "cancelled" && (
                              <Badge bg="danger">Cancelled</Badge>
                            )}

                            {product.status === "pending" && (
                              <Badge bg="warning">Pending</Badge>
                            )}
                          </h4>
                        </td>
                        <td>
                          {" "}
                          {moment(new Date(product.createdAt)).format(
                            "M/D/YYYY, h:mm:ss A"
                          )}
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip>View Details</Tooltip>}
                            >
                              <Button
                                variant="info"
                                size="small"
                                className="btn btn-sm  px-2 py-1"
                                onClick={() => {
                                  setSingleOrder(product);
                                  onOpenModal();
                                }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <b>Download Label</b>
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-sm btn-success mr-2  px-2 py-1"
                                onClick={() => {
                                  downloadPdf(product._id, product.ToName);
                                }}
                              >
                                <i class="bi bi-file-earmark-arrow-down-fill"></i>
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <b>Delete Label</b>
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-sm btn-danger  px-2 py-1"
                                onClick={() => {
                                  deleteOrder(product._id);
                                }}
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                  {loaders && (
                    <tr>
                      <td colSpan="12" className="text-center">
                        <div className="text-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <nav>
                <ul className="pagination">
                  <li
                    className={page === 1 ? "page-item disabled" : "page-item"}
                  >
                    <Link
                      className="page-link"
                      to=""
                      onClick={(e) => {
                        setPage(page - 1);
                      }}
                    >
                      Prev
                    </Link>
                  </li>

                  <li className="page-item active" aria-current="page">
                    <Link className="page-link" to="">
                      {page}
                    </Link>
                  </li>

                  <li
                    className={
                      page === totalPages ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      to=""
                      tabIndex={-1}
                      aria-disabled={page === totalPages ? true : false}
                      onClick={(e) => {
                        setPage(page + 1);
                      }}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <Modal
          show={showOrderDetails}
          onHide={onCloseOrderDetailsModal}
          backdrop="static"
          keyboard={false}
          size={"lg"}
          centered={true}
        >
          <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
            <Modal.Title id="modal-title">
              <h3> View Order </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title text-center">From</h5>
                  </div>
                  <div className="card-body">
                    <div className="card-text">
                      <span className="fw-bold">Name:</span> {order.FromName}{" "}
                      <br />
                      <span className="fw-bold">Company:</span>{" "}
                      {order.FromCompany} <br />
                      <span className="fw-bold">Street:</span>{" "}
                      {order.FromStreet} <br />
                      <span className="fw-bold">Street2:</span>{" "}
                      {order.FromStreet2} <br />
                      <span className="fw-bold">City:</span> {order.FromCity}{" "}
                      <br />
                      <span className="fw-bold">State:</span> {order.FromState}{" "}
                      <br />
                      <span className="fw-bold">Zip:</span> {order.FromZip}{" "}
                      <br />
                      <span className="fw-bold">Country:</span>{" "}
                      {order.FromCountry} <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title text-center">To</h5>
                  </div>
                  <div className="card-body">
                    <div className="card-text">
                      <span className="fw-bold">Name:</span> {order.ToName}{" "}
                      <br />
                      <span className="fw-bold">Company:</span>{" "}
                      {order.ToCompany} <br />
                      <span className="fw-bold">Street:</span> {order.ToStreet}{" "}
                      <br />
                      <span className="fw-bold">Street2:</span>{" "}
                      {order.ToStreet2} <br />
                      <span className="fw-bold">City:</span> {order.ToCity}{" "}
                      <br />
                      <span className="fw-bold">State:</span> {order.ToState}{" "}
                      <br />
                      <span className="fw-bold">Zip:</span> {order.ToZip} <br />
                      <span className="fw-bold">Country:</span>{" "}
                      {order.ToCountry} <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title text-center">
                      Additional details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="card-text">
                      <span className="fw-bold">Label Type:</span>{" "}
                      {order.labelType?.name} <br />
                      <span className="fw-bold">Weight:</span> {order.Weight}
                      {order.labelType?.name.includes("First Class") ? (
                        <span className="fw-bold">oz</span>
                      ) : (
                        <span className="fw-bold">lb</span>
                      )}{" "}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {show ? (
          <ViewDetails
            isOpen={show}
            onClose={onCloseModal}
            details={singleOrder}
          />
        ) : null}
      </>
    </div>
  );
};

export default AllOrders;
