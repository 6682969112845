import React from "react";

const StatsCard = ({ title, value, icon }) => {
  return (
    <span
      className="card card-hover-shadow h-100"
      style={{
        cursor: "pointer",
      }}
    >
      <div className="card-body">
        <h6 className="card-subtitle">{title}</h6>
        <div className="row align-items-center gx-2 mb-1">
          <div className="col-6">
            <h2 className="card-title text-inherit">{value}</h2>
          </div>
          <div className="col-6">
            <div className="chartjs-custom" style={{ height: "3rem" }}>
              <div className="w-100 text-end display-4">
                <i className={icon}></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default StatsCard;
