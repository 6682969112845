import axios from "axios";
import React, { useEffect, useState } from "react";
import { config, server } from "../env";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import StatsCard from "../components/StatsCard";

const Tracking = () => {
  const [trackings, setTrackings] = useState([]);
  const [trackingInfo, setTrackingInfo] = useState({});
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [lableTypeSwift, setlableTypeSwift] = useState("");
  const [lableName, setlableName] = useState("");
  const [single, setSingle] = useState({});
  const [url, setUrl] = useState("/v1/tracking/readBulk/");
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [loaders, setLoaders] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [showAddLabel, setShowAddLabel] = useState(false);
  const onCloseAddLabelModal = () => {
    setShowAddLabel(false);
  };
  const onOpenAddLabelModal = () => setShowAddLabel(true);

  const getTrackings = async (search) => {
    var link = "";
    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
    }
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + link, config)
      .then((res) => {
        setTrackings(res.data.trackings);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };

  useEffect(() => {
    getTrackings();
  }, [page, limit]);

  const addtracking = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);
    setGenerateLoading(true);

    const data = {
      amount: e.target.amount.value,
      type: e.target.type.value,
      trackRef: e.target.trackRef.value,
    };

    await axios
      .post(server + "/v1/tracking/create", data, config)
      .then((res) => {
        fetchIncomings();
        getTrackings();
        setLoader("");
        setGenerateLoading(false);
        onCloseAddLabelModal();
        toast.success(res.data.message);
      })
      .catch((err) => {
        setLoader("");
        setGenerateLoading(false);
        toast.error(err.response.data.message);
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };
  const DeleteTrackings = async (id) => {
    await axios
      .delete(server + "/v1/tracking/delete", config)
      .then((res) => {
        getTrackings();
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    fetchIncomings();
  }, []);

  const fetchIncomings = async () => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + "/v1/tracking/stats", config)
      .then((res) => {
        setTrackingInfo(res.data);

        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Trackings" />
      <div className="row">
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <StatsCard
              title={`USPS PRIORITY LEFT`}
              value={`${trackingInfo?.left?.priorityLeft ?? 0}`}
              icon={"bi bi-bookmark-star-fill"}
            />
          )}
        </div>
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <StatsCard
              title={`USPS EXPRESS LEFT`}
              value={`${trackingInfo?.left?.expressLeft ?? 0}`}
              icon={"bi bi-bookmark-star-fill"}
            />
          )}
        </div>
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <StatsCard
              title={`USPS GROUND_OZ LEFT`}
              value={`${trackingInfo?.left?.ground_ozLeft ?? 0}`}
              icon={"bi bi-bookmark-star-fill"}
            />
          )}
        </div>
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <StatsCard
              title={`USPS GROUND_LBS LEFT`}
              value={`${trackingInfo?.left?.ground_lbsLeft ?? 0}`}
              icon={"bi bi-bookmark-star-fill"}
            />
          )}
        </div>
        <div className="col-sm-6 col-lg-4 mb-3 mb-lg-5">
          {loaders ? (
            loader
          ) : (
            <StatsCard
              title={`USPS SIGNATURE LEFT`}
              value={`${trackingInfo?.left?.signatureLeft ?? 0}`}
              icon={"bi bi-bookmark-star-fill"}
            />
          )}
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center w-100 mb-3">
        <div className="d-flex justify-content-center align-items-center gap-2">
          <button
            className="btn btn-danger btn-sm rounded-3 "
            onClick={() => {
              DeleteTrackings();
            }}
          >
            <i className="bi bi-trash-fill"></i>
            Delete Used Trackings
          </button>
          <button
            className="btn btn-primary btn-sm rounded-3 "
            onClick={() => {
              onOpenAddLabelModal();
            }}
          >
            <i className="bi-plus"></i>
            Generate Trackings
          </button>
        </div>
      </div>

      <>
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">Trackings</h5>
              <div className="btn-group align-items-center gap-2">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    maxWidth: "200px",
                  }}
                  placeholder="Search..."
                  onChange={(e) => {
                    getTrackings(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Type</th>
                    <th>Tracking</th>
                    <th>Amount/Generated</th>
                    <th>Status</th>

                    {/* <th>Action</th> */}
                  </tr>
                </thead>

                <tbody>
                  {trackings?.map((pricing, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{pricing.type}</td>
                      <td>{pricing.code}</td>
                      <td>
                        {pricing.amount} / {pricing.remaining}
                      </td>
                      <td>
                        {!pricing.status ? (
                          <span className="badge bg-danger">Pending</span>
                        ) : (
                          <span className="badge bg-success">Completed</span>
                        )}
                      </td>

                      {/* <td>
                        <Link
                          to={"/pricing/" + pricing._id}
                          className="btn btn-sm btn-primary me-2 px-2 py-1"
                          title="Manage Pricing"
                        >
                          <i class="bi bi-file-earmark-plus-fill"></i>
                        </Link>
                        <button
                          className="btn btn-sm btn-warning me-2 px-2 py-1"
                          data-bs-toggle="modal"
                          data-bs-target="#edit"
                          onClick={() => {
                            setSingle(pricing);
                            onOpenEditLabelModal();
                          }}
                          title="Edit"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                       
                     
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <nav>
                <ul className="pagination">
                  <li
                    className={page === 1 ? "page-item disabled" : "page-item"}
                  >
                    <Link
                      className="page-link"
                      to=""
                      onClick={(e) => {
                        setPage(page - 1);
                      }}
                    >
                      Prev
                    </Link>
                  </li>

                  <li className="page-item active" aria-current="page">
                    <Link className="page-link" to="">
                      {page}
                    </Link>
                  </li>

                  <li
                    className={
                      page === totalPages ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      to=""
                      tabIndex={-1}
                      aria-disabled={page === totalPages ? true : false}
                      onClick={(e) => {
                        setPage(page + 1);
                      }}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </>

      {/* create modal */}

      <Modal
        show={showAddLabel}
        onHide={onCloseAddLabelModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3>Add Label Type </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addtracking}>
            <div className="form-group mb-3">
              <label>Type</label>
              <select
                name="type"
                id=""
                className="form-control"
                onChange={(e) => setlableTypeSwift(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  select
                </option>

                <option value="priority">Priority</option>
                <option value="express">Express</option>
                <option value="ground_oz">Ground(OZ)</option>
                <option value="ground_lbs">Ground(LBS)</option>
                <option value="signature">Signature</option>
              </select>
            </div>
            <div className="form-group mb-3">
              <label>Tracking </label>
              <input
                type="text"
                className="form-control"
                name="trackRef"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Amount</label>
              <input
                type="text"
                className="form-control"
                name="amount"
                required
              />
            </div>

            <div className="d-flex mt-2 justify-content-between ">
              <button
                type="button"
                className="btn btn-white "
                onClick={() => onCloseAddLabelModal()}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                {generateLoading ? loader : "Add"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Tracking;
