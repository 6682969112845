const BarChart = (dom, payload, bgColor, labelHeading) => {
  var Chart = window.Chart;
  new Chart(dom, {
    type: "bar",
    options: {
      responsive: true,
      legend: {
        position: "top",
        FontFace: "Arial",
        // font size
        labels: {
          fontSize: 15,

          // font color
          fontColor: "white",
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },
          },
        ],
      },
    },

    data: {
      labels: payload.labels,

      // dont show y axis below 0

      datasets: [
        {
          label: labelHeading,
          backgroundColor: bgColor,
          data: payload.data,
        },
      ],
    },
  });
  return Chart;
};
export default BarChart;
