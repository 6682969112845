import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { server } from "../env";
import { toast } from "react-toastify";
import Header from "../components/Header";

const CustomPricing = () => {
  const { id } = useParams();
  const [pricing, setPricing] = useState({});
  const [labelTypes, setLabelTypes] = useState([]);
  const [user, setUser] = useState({});
  const [labelTypeId, setLabelTypeId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  console.log(id);
  const [showUser, setShowUser] = useState(false);
  const onCloseUserModal = () => {
    setShowUser(false);
  };
  const onOpenUserModal = () => setShowUser(true);

  useEffect(() => {
    const readLabelTypes = async () => {
      await axios
        .get(server + "/v1/labeltype/read", {
          headers: {
            Authorization: "Bearer " + Cookies.get("footprint"),
          },
        })
        .then((res) => {
          setLabelTypes(res.data.labelTypes);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    readLabelTypes();
    readUser();
  }, [id]);

  const readUser = async () => {
    await axios
      .get(server + "/v1/admin/user/read/" + id, {
        headers: {
          Authorization: "Bearer " + Cookies.get("footprint"),
        },
      })
      .then((res) => {
        setUser(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(user);

  const addCustomPricing = async (e) => {
    e.preventDefault();

    const data = {
      labelType: e.target.labelType.value,
      price: e.target.price.value,
      fromWeight: e.target.fromWeight.value,
      toWeight: e.target.toWeight.value,
    };
    console.log(user._id);
    await axios
      .put(server + "/v1/admin/custom-pricing/" + id, data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("footprint"),
        },
      })
      .then((res) => {
        readUser();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message);
      });
  };

  const deleteCustomPricing = async (index) => {
    const data = {
      index,
    };

    await axios
      .post(server + "/v1/admin/custom-pricing/delete/" + id, data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("footprint"),
        },
      })
      .then((res) => {
        readUser();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message);
      });
  };

  return (
    <div className="content container-fluid">
      <Header title="Custom Pricing" />
      <>
        <div className="nk-block-head-content  w-100">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Link to="/api-users" className="btn btn-sm btn-warning">
              <i class="bi bi-arrow-left"></i> Go Back
            </Link>
            <button
              className="btn btn-info"
              data-toggle="modal"
              data-target="#create"
              onClick={() => onOpenUserModal()}
            >
              <i className="bi-plus "></i> Add Price
            </button>
          </div>
        </div>

        <div className="card-body px-0 pb-0">
          <table className="table ">
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>labelType</th>
                <th>From Weight</th>
                <th>To Weight</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {user?.customPricing?.map((price, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{price.labelType}</td>
                  <td>{price.fromWeight}</td>
                  <td>{price.toWeight}</td>
                  <td>${price.price}</td>
                  <td>
                    {/* <button className="btn btn-primary me-2">Edit</button> */}
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          <b>Delete Pricing</b>
                        </Tooltip>
                      }
                    >
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteCustomPricing(index)}
                      >
                        <i class="bi bi-trash3"></i>
                      </button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>

      {/* create Pricing */}

      <Modal
        show={showUser}
        onHide={onCloseUserModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3 className="text-info">Add weight </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addCustomPricing}>
            <div className="form-group mb-3">
              <label>LabelTypes</label>
              <select className="form-control" name="labelType">
                {labelTypes.map((labelType, index) => (
                  <option key={index} value={labelType.name}>
                    {labelType.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label>From Weight</label>
              <input
                type="number"
                className="form-control"
                name="fromWeight"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>To Weight</label>
              <input
                type="number"
                className="form-control"
                name="toWeight"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Price</label>
              <input
                type="number"
                className="form-control"
                name="price"
                required
                step={0.01}
              />
            </div>
            <div className="d-flex mt-2 justify-content-end ">
              <button
                type="button"
                className="btn btn-white me-3"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomPricing;
