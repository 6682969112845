import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Route, Routes } from "react-router-dom";

import Users from "../source/Users";
import Stats from "../source/Stats";
import Orders from "../source/Orders";
import Pricing from "../source/Pricing";
import SinglePricing from "../source/SinglePricing";
import Tracking from "../source/Tracking";
import AllOrders from "../source/AllOrders";
import CustomPricing from "../source/CustomPricing";
import Settings from "../source/Settings";
import Invoices from "../source/Invoices";

const DashboardRoutes = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main id="content" role="main" className="main">
        <Routes>
          <Route path="/stats" element={<Stats />} />
          <Route path="/orders/:id" element={<Orders />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/users" element={<Users />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/pricing/:id" element={<SinglePricing />} />
          <Route path="/all-orders" element={<AllOrders />} />
          <Route path="/custom-pricing/:id" element={<CustomPricing />} />
          <Route path="/*" element={<Stats />} />
        </Routes>
      </main>
    </>
  );
};

export default DashboardRoutes;
