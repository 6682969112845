import React, { useEffect, useState } from "react";
import { Badge, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Header from "../components/Header";
import axios from "axios";
import { config, server } from "../env";
import { toast } from "react-toastify";

const Pricing = () => {
  const [pricings, setPricings] = useState([]);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [lableTypeSwift, setlableTypeSwift] = useState("");
  const [lableName, setlableName] = useState("");
  const [single, setSingle] = useState({});

  const [showAddLabel, setShowAddLabel] = useState(false);

  const onCloseAddLabelModal = () => {
    setShowAddLabel(false);
  };
  const onOpenAddLabelModal = () => setShowAddLabel(true);

  const [showEditLabel, setShowEditLabel] = useState(false);

  const onCloseEditLabelModal = () => {
    setShowEditLabel(false);
  };
  const onOpenEditLabelModal = () => setShowEditLabel(true);

  const [showDeleteLabel, setShowDeleteLabel] = useState(false);

  const onCloseDeleteLabelModal = () => {
    setShowDeleteLabel(false);
  };
  const onOpenDeleteLabelModal = () => setShowDeleteLabel(true);

  const getPricings = async () => {
    await axios
      .get(server + "/v1/labeltype/read", config)
      .then((res) => {
        setPricings(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPricings();
  }, []);

  const addPricing = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      name: e.target.name.value,
      uid: e.target.uid.value,
      maxWeight: e.target.maxWeight.value,
      type: e.target.type.value,
      discount: e.target.discount.value,
    };

    await axios
      .post(server + "/v1/labeltype/create", data, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        getPricings();
        onCloseAddLabelModal();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const updatePricing = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);

    const data = {
      name: single.name,
      uid: single.uid,
      maxWeight: single.maxWeight,
      type: single.type,
      discount: single.discount,
    };

    await axios
      .post(server + "/v1/labeltype/update/" + single._id, data, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        getPricings();
        onCloseEditLabelModal();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const enableDisableLabelType = async (id) => {
    await axios
      .put(server + "/v1/labeltype/enableDisableLabelType/" + id, {}, config)
      .then((res) => {
        getPricings();
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const DeleteLabelType = async (id) => {
    await axios
      .delete(server + "/v1/labeltype/delete/" + id, config)
      .then((res) => {
        getPricings();
        toast.success(res.data.message);
        onCloseDeleteLabelModal();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Pricing" />

      <div className="d-flex justify-content-end align-items-center w-100 mb-3">
        <button
          className="btn btn-primary rounded-3"
          onClick={() => {
            onOpenAddLabelModal();
          }}
        >
          <i className="bi-plus"></i>
          Add Label Type
        </button>
      </div>

      <>
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">Label Types</h5>
              {/* <div className="btn-group align-items-center gap-2">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    maxWidth: "200px",
                  }}
                  placeholder="Search..."
                  onChange={(e) => {
                    fetchIncomings(e.target.value);
                  }}
                />
              </div> */}
            </div>
          </div>

          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>MaxWeight</th>
                    <th>Type</th>
                    <th>UID</th>
                    <th>Discount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {pricings.map((pricing, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{pricing.name}</td>
                      <td>{pricing.maxWeight}</td>
                      <td>{pricing.type}</td>
                      <td>{pricing.uid}</td>
                      <td>{pricing.discount}%</td>
                      <td>
                        {pricing.status ? (
                          <span className="badge bg-success">Active</span>
                        ) : (
                          <span className="badge bg-danger">Inactive</span>
                        )}
                      </td>

                      <td>
                        <Link
                          to={"/pricing/" + pricing._id}
                          className="btn btn-sm btn-primary me-2 px-2 py-1"
                          title="Manage Pricing"
                        >
                          <i class="bi bi-file-earmark-plus-fill"></i>
                        </Link>
                        <button
                          className="btn btn-sm btn-warning me-2 px-2 py-1"
                          data-bs-toggle="modal"
                          data-bs-target="#edit"
                          onClick={() => {
                            setSingle(pricing);
                            onOpenEditLabelModal();
                          }}
                          title="Edit"
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>
                        {pricing.status ? (
                          <button
                            className="btn btn-sm btn-danger me-2 px-2 py-1"
                            title="Disable"
                            onClick={() => enableDisableLabelType(pricing._id)}
                          >
                            <i class="bi bi-bookmark-x-fill"></i>
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-success me-2 px-2 py-1"
                            title="Enable"
                            onClick={() => enableDisableLabelType(pricing._id)}
                          >
                            <i class="bi bi-bookmark-check-fill"></i>
                          </button>
                        )}
                        <button
                          className="btn btn-sm btn-warning me-2 px-2 py-1"
                          data-bs-toggle="modal"
                          data-bs-target="#edit"
                          onClick={() => {
                            setSingle(pricing);
                            onOpenDeleteLabelModal();
                          }}
                          title="Edit"
                        >
                          <i class="bi bi-trash-fill"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <div className="d-flex justify-content-end mt-5">
              <nav>
                <ul className="pagination">
                  <li
                    className={page === 1 ? "page-item disabled" : "page-item"}
                  >
                    <Link
                      className="page-link"
                      to=""
                      onClick={(e) => {
                        setPage(page - 1);
                      }}
                    >
                      Prev
                    </Link>
                  </li>

                  <li className="page-item active" aria-current="page">
                    <Link className="page-link" to="">
                      {page}
                    </Link>
                  </li>

                  <li
                    className={
                      page === totalPages ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      to=""
                      tabIndex={-1}
                      aria-disabled={page === totalPages ? true : false}
                      onClick={(e) => {
                        setPage(page + 1);
                      }}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div> */}
          </div>
        </div>
      </>

      {/* create modal */}

      <Modal
        show={showAddLabel}
        onHide={onCloseAddLabelModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3>Add Label Type </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addPricing}>
            <div className="form-group mb-3">
              <label>Type</label>
              <select
                name="type"
                id=""
                className="form-control"
                onChange={(e) => setlableTypeSwift(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  select
                </option>

                <option value="rbl">RBL</option>
                <option value="ups">UPS</option>
              </select>
            </div>

            <div className="form-group mb-3">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label>UID</label>
              <input type="text" className="form-control" name="uid" required />
            </div>

            <div className="form-group mb-3">
              <label>Max Weight</label>
              <input
                type="number"
                className="form-control"
                name="maxWeight"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label>Discount(%)</label>
              <input
                type="number"
                className="form-control"
                name="discount"
                required
              />
            </div>

            <div className="d-flex mt-2 justify-content-between ">
              <button
                type="button"
                className="btn btn-white "
                onClick={() => onCloseAddLabelModal()}
                s
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* edit modal */}

      <Modal
        show={showEditLabel}
        onHide={onCloseEditLabelModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3> Edit Label Type </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={updatePricing}>
            <div className="form-group mb-3">
              <label>Type</label>
              <select
                name="type"
                id=""
                className="form-control"
                value={single.type}
                onChange={(e) => setSingle({ ...single, type: e.target.value })}
              >
                <option value="rbl">RBL</option>
                <option value="ups">UPS</option>
              </select>
            </div>

            <div className="form-group mb-3">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                required
                value={single.name}
                onChange={(e) => setSingle({ ...single, name: e.target.value })}
              />
            </div>

            <div className="form-group mb-3">
              <label>UID</label>
              <input
                type="text"
                className="form-control"
                name="uid"
                required
                value={single.uid}
                onChange={(e) => setSingle({ ...single, uid: e.target.value })}
              />
            </div>

            <div className="form-group mb-3">
              <label>Max Weight</label>
              <input
                type="number"
                className="form-control"
                name="maxWeight"
                required
                value={single.maxWeight}
                onChange={(e) =>
                  setSingle({ ...single, maxWeight: e.target.value })
                }
              />
            </div>

            <div className="form-group mb-3">
              <label>Discount(%)</label>
              <input
                type="number"
                className="form-control"
                name="discount"
                required
                value={single.discount}
                onChange={(e) =>
                  setSingle({ ...single, discount: e.target.value })
                }
              />
            </div>

            <div className="d-flex mt-2 justify-content-between ">
              <button
                type="button"
                className="btn btn-white "
                onClick={() => onCloseEditLabelModal()}
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Update {loader}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* delete label */}
      <Modal
        show={showDeleteLabel}
        onHide={onCloseDeleteLabelModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="border-bottom-0" closeButton>
          <Modal.Title id="modal-title mb-0">
            <h4 className="text-danger mb-0">Delete Label Type </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-0">
          <div className="text-center mt-0">
            <p>Are you sure want to delete this label?</p>
          </div>

          <div className="d-flex justify-content-between ">
            <button
              type="button"
              className="btn btn-default"
              onClick={() => onCloseDeleteLabelModal()}
            >
              Close
            </button>
            <button
              className="btn btn-danger"
              onClick={() => DeleteLabelType(single._id)}
            >
              Delete {loader}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Pricing;
