import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Card, Col, Modal, Row, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { config, server } from "../env";
import Loader from "../components/Loader";
import { toast } from "react-toastify";

const Settings = () => {
  const [url, setUrl] = useState("");
  const [loader, setLoader] = useState("");
  const [data, setData] = useState({});
  const [showAddLabel, setShowAddLabel] = useState(false);
  const onCloseAddLabelModal = () => {
    setShowAddLabel(false);
  };
  const onOpenAddLabelModal = () => setShowAddLabel(true);
  useEffect(() => {
    readRblUrl();
  }, []);
  const readRblUrl = async () => {
    await axios
      .get(server + "/v1/admin/read-url", config)
      .then((res) => {
        setUrl(res.data.RblUrl === null ? "" : res.data.RblUrl.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(data);
  const updateURL = async (e) => {
    e.preventDefault();
    const params = {
      url,
    };
    await axios
      .put(server + "/v1/admin/update-url", params, config)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const [creds, setCreds] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const colors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
  ];

  const readCreds = async () => {
    await axios
      .get(server + "/v1/creds/read", config)
      .then((rsp) => {
        setCreds(rsp.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const saveCreds = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const data = {
      clientId: e.target.clientId.value,
      clientSecret: e.target.clientSecret.value,
      accounts: accounts,
      note: e.target.noteDescription.value,
    };

    await axios
      .post(server + "/v1/creds/create", data, config)
      .then((rsp) => {
        setLoader("");
        toast.success(rsp.data.message);
        readCreds();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const deleteCreds = async (id) => {
    await axios
      .delete(server + "/v1/creds/delete/" + id, config)
      .then((rsp) => {
        setLoader("");
        toast.success(rsp.data.message);
        readCreds();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const updateCreds = async (id, isEnable) => {
    setLoader(<Loader />);
    const data = {
      id: id,
      isEnable: isEnable,
    };

    await axios
      .post(server + "/v1/creds/update", data, config)
      .then((rsp) => {
        setLoader("");
        toast.success(rsp.data.message);
        readCreds();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const runCheckCreds = async () => {
    setLoader(<Loader />);

    await axios
      .get(server + "/v1/creds/checkcreds", config)
      .then((rsp) => {
        setLoader("");
        toast.success(rsp.data.message);
        readCreds();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    readCreds();
  }, []);

  return (
    <div className="content container-fluid">
      <Header title="Website Settings" />
      <div className="col-lg-12 mb-4">
        <Card>
          <Card.Header>
            <h4>RBL Endpoint</h4>
          </Card.Header>
          <Card.Body>
            <form className="row" onSubmit={updateURL}>
              <div className="col-10  align-items-center text-center gap-2">
                <div className="form-group w-100">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="http://rbltech.io/api/create"
                    name="url"
                    style={{ width: "70%" }}
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-2 d-flex align-items-left justify-content-left text-start">
                <button type="submit" className="btn btn-primary">
                  Update Url
                </button>
              </div>
            </form>
          </Card.Body>

          {/* <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Ticker</th>

                    <th scope="col">Address</th>
                    <th scope="col">Confirmations</th>
                    <th scope="col">Color</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cryptos.map((crypto, index) => (
                    <tr key={index}>
                      <td>{crypto.name}</td>
                      <td>{crypto.ticker}</td>
                      <td>
                        {crypto?.address?.length > 50
                          ? crypto?.address?.slice(0, 49)
                          : crypto.address}
                      </td>
                      <td>{crypto.confirmations}</td>
                      <td>{crypto?.color}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => deleteCrypto(crypto._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div> */}
        </Card>
      </div>

      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="card-title">UPS Creds</h4>
                
                <div class="p-2">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => onOpenAddLabelModal()}
                  >
                    Create Creds
                  </button>

                  <button
                    className="btn btn-sm btn-warning"
                    style={{marginLeft: "10px"}}
                    onClick={() => runCheckCreds()}
                  >
                    Run check Creds {loader}
                  </button>
                </div>

            
              </div>
            </Card.Header>
            <Card.Body>
              {/* table */}
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Client ID</th>
                      <th scope="col">Client Secret</th>
                      <th scope="col">Accounts</th>
                      <th scope="col">Status</th>
                      <th scope="col">Note</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {creds.map((cred, index) => (
                      <tr key={index}>
                        <td>{cred.clientId}</td>
                        <td>{cred.clientSecret}</td>
                        <td>
                          {cred.accounts.map((account, index) => (
                            <span
                              className="badge badge-info mr-1"
                              style={{
                                backgroundColor:
                                  colors[
                                    Math.floor(Math.random() * colors.length)
                                  ],
                              }}
                            >
                              {account}
                            </span>
                          ))}
                        </td>
                        
                        {/* <td>
                          {cred.isEnable === '1' ? (
                            <span
                              className="badge badge-info mr-1"
                              style={{
                                backgroundColor: "#00c9a7",
                              }}
                            >
                              Enabled
                            </span>
                          ) : (
                            <span
                              className="badge badge-info mr-1"
                              style={{
                                backgroundColor: "#E91E63",
                              }}
                            >
                              Disabled
                            </span>
                          )}
                        </td>

                        <td>
                            <h4>
                              {cred.status === "No Work" && (
                                <Badge bg="danger">{cred.status}</Badge>
                              )}
                              {cred.status  === "Work" && (
                                <Badge bg="success">{cred.status}</Badge>
                              )}
                          </h4>
                        </td> */}

                        <td>
                          {cred.isEnable === '1' ? 'Enabled' : 'Disabled'} / {cred.status}
                        </td>

                        <td>{cred.note}</td>

                        <td>
                          <div className="d-flex gap-2">
                              <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      <b>{cred.isEnable !== '1' ? 'Enabled' : 'Disabled'}</b>
                                    </Tooltip>
                                  }
                                >
                                  <button
                                    className="btn btn-sm btn-warning px-2 py-1"
                                    onClick={() => {
                                      updateCreds(cred._id, cred.isEnable === '1' ? '0' : '1');
                                    }}
                                  >
                                    {cred.isEnable !== '1' ? (
                                      <i class="bi bi-check-circle"></i>
                                    ) :
                                    (
                                      <i class="bi bi-x-circle"></i>
                                    )}
                                  </button>
                                </OverlayTrigger>

                              <OverlayTrigger
                                  placement="top"
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                  overlay={
                                    <Tooltip>
                                      <b>Delete Creds</b>
                                    </Tooltip>
                                  }
                                >
                                  <button
                                    className="btn btn-sm btn-danger  px-2 py-1"
                                    onClick={() => {
                                      deleteCreds(cred._id);
                                    }}
                                  >
                                    <i class="bi bi-trash"></i>
                                  </button>
                                </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* create creds */}

      <Modal
        show={showAddLabel}
        onHide={onCloseAddLabelModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3> Add UPS Creds </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={saveCreds}>
            <div className="form-group mb-3">
              <label>ClientId</label>
              <input
                type="text"
                className="form-control"
                name="clientId"
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>ClientSecret</label>
              <input
                type="text"
                className="form-control"
                name="clientSecret"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label>Accounts</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="account"
                  id="account"
                />

                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="button-addon2"
                    onClick={(e) => {
                      if (document.getElementById("account").value === "")
                        return toast.error("Please enter account");

                      setAccounts([
                        ...accounts,
                        document.getElementById("account").value,
                      ]);
                      document.getElementById("account").value = "";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            {accounts.length > 0 && (
              <div className="form-group mb-3">
                {accounts.map((account, index) => (
                  <span
                    className="badge p-1 text-white mr-2 mb-2"
                    key={index}
                    style={{
                      background: colors[index],
                      borderRadius: "10px",
                    }}
                  >
                    {account}
                    <span
                      className="text-white ml-2"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setAccounts(
                          accounts.filter((item) => item !== account)
                        );
                      }}
                    >
                      X
                    </span>
                  </span>
                ))}
              </div>
            )}

            <div className="form-group mb-3">
              <label>Note</label>
              <textarea
                rows="3"
                className="form-control"
                name="noteDescription"
              />
            </div>

            <div className="d-flex mt-2 justify-content-end ">
              <button
                type="button"
                className="btn btn-light mr-3"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Add {loader}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Settings;
