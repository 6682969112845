import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Loader from "../components/Loader";
import axios from "axios";
import { config, server } from "../env";
import { Badge, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import moment from "moment/moment";
import BarChart from "../components/BarChart";
import ViewDetails from "./OrderDetails";
const Orders = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState("");
  const [statsData, setStatsData] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [userData, setUserData] = useState({});
  const [loaders, setLoaders] = useState(false);
  const [userOrdersStats, setUserOrdersStats] = useState({});
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [pageLog, setPageLog] = useState(1);
  const [limitLog, setLimitLog] = useState(10);
  const [totalPagesLog, setTotalPagesLog] = useState(0);
  const [totalLog, setTotalLog] = useState(0);
  const [url, setUrl] = useState("/v1/admin/user-orders/");
  const [order, setOrder] = useState({});
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  const onCloseOrderDetailsModal = () => {
    setShowOrderDetails(false);
  };
  const onOpenOrderDetailsModal = () => setShowOrderDetails(true);
  useEffect(() => {
    fetchIncomings();
  }, [page, limit]);
  useEffect(() => {
    fetchStats();
    fetchUser();
    fetchUsersOrdersStats();
    getOrdersBarChart();
  }, []);
  useEffect(() => {
    fetchUserLogs();
  }, [pageLog, limitLog]);
  const fetchIncomings = async (search) => {
    var link = "";
    if (search) {
      link =
        url +
        "?id=" +
        id +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&search=" +
        search;
    } else {
      link = url + "?id=" + id + "&page=" + page + "&limit=" + limit;
    }
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + link, config)
      .then((res) => {
        setProducts(res.data.orders);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };
  console.log(userData);
  const fetchStats = async () => {
    await axios
      .get(server + `/v1/admin/user/stats/${id}`, config)
      .then((res) => {
        setStatsData(res.data.labelTypesData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchUser = async () => {
    await axios
      .get(server + `/v1/admin/user/read/${id}`, config)
      .then((res) => {
        setUserData(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchUserLogs = async (search) => {
    var link = "";
    if (search) {
      link =
        "/v1/admin/user/logs/" +
        "?id=" +
        id +
        "&page=" +
        pageLog +
        "&limit=" +
        limitLog +
        "&search=" +
        search;
    } else {
      link =
        "/v1/admin/user/logs/" +
        "?id=" +
        id +
        "&page=" +
        pageLog +
        "&limit=" +
        limitLog;
    }
    await axios
      .get(server + link, config)
      .then((res) => {
        setLogsData(res.data.logs);
        setTotalPagesLog(res.data.totalPages);
        setTotalLog(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchUsersOrdersStats = async () => {
    await axios
      .get(server + `/v1/admin/user/orders-stats/${id}`, config)
      .then((res) => {
        setUserOrdersStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOrdersBarChart = async () => {
    await axios
      .get(server + `/v1/admin/read-users-orders-chart/${id}`, config)
      .then((res) => {
        const e = document.getElementById("order_chart");
        var canvas = document.createElement("CANVAS");
        while (e.firstChild) {
          e.removeChild(e.firstChild);
        }
        e.appendChild(canvas);
        BarChart(canvas, res.data, "blue", "Orders");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="product" />

      <>
        <div className="card mb-4">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">Label Type Stats</h5>
            </div>
          </div>
          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr.No.</th>
                    <th scope="col">Label Type</th>
                    <th scope="col">Today's Orders</th>
                    <th scope="col">Week Orders</th>
                    <th scope="col">Month Orders</th>
                    <th scope="col">AllTime Orders</th>
                  </tr>
                </thead>

                <tbody>
                  {statsData.length === 0 ? (
                    <tr>
                      <td colSpan="12" className="text-center">
                        <span className="text-center">No Orders</span>
                      </td>
                    </tr>
                  ) : (
                    statsData?.map((product, i) => (
                      <tr key={product._id}>
                        <td>{i + 1}</td>
                        <td>{product.labelType}</td>
                        <td>{product.countByTimePeriod.day}</td>
                        <td>{product.countByTimePeriod.isoWeek}</td>
                        <td>{product.countByTimePeriod.month}</td>
                        <td>{product.countByTimePeriod.alltime}</td>
                      </tr>
                    ))
                  )}
                  {loaders && (
                    <tr>
                      <td colSpan="12" className="text-center">
                        <div className="text-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Order Count */}
        <div className=" mb-4 row">
          <div className="col-12 ">
            <div className="card ">
              <div className="card-header">
                <div className="card-title ">
                  <h4 className="title text-center ">Orders Count</h4>
                </div>
              </div>
              <div className="card-body row">
                <div className="col-sm-6 col-lg-3 mb-3 ">
                  {loaders ? (
                    loader
                  ) : (
                    <span
                      className="card card-hover-shadow h-100"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className="card-body">
                        <h6 className="card-subtitle">TODAY</h6>
                        <div className="row align-items-center gx-2 mb-1">
                          <div className="col-6">
                            <h2 className="card-title text-inherit">
                              {userOrdersStats.today}
                            </h2>
                          </div>
                          <div className="col-6">
                            <div
                              className="chartjs-custom"
                              style={{ height: "3rem" }}
                            >
                              <div className="w-100 text-end display-4">
                                <i class="bi bi-cart-check-fill"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  )}
                </div>
                <div className="col-sm-6 col-lg-3 mb-3 ">
                  {loaders ? (
                    loader
                  ) : (
                    <span
                      className="card card-hover-shadow h-100"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className="card-body">
                        <h6 className="card-subtitle">WEEK</h6>
                        <div className="row align-items-center gx-2 mb-1">
                          <div className="col-6">
                            <h2 className="card-title text-inherit">
                              {userOrdersStats.week}
                            </h2>
                          </div>
                          <div className="col-6">
                            <div
                              className="chartjs-custom"
                              style={{ height: "3rem" }}
                            >
                              <div className="w-100 text-end display-4">
                                <i class="bi bi-cart-check-fill"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  )}
                </div>
                <div className="col-sm-6 col-lg-3 mb-3 ">
                  {loaders ? (
                    loader
                  ) : (
                    <span
                      className="card card-hover-shadow h-100"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className="card-body">
                        <h6 className="card-subtitle">MONTH</h6>
                        <div className="row align-items-center gx-2 mb-1">
                          <div className="col-6">
                            <h2 className="card-title text-inherit">
                              {userOrdersStats.month}
                            </h2>
                          </div>
                          <div className="col-6">
                            <div
                              className="chartjs-custom"
                              style={{ height: "3rem" }}
                            >
                              <div className="w-100 text-end display-4">
                                <i class="bi bi-cart-check-fill"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  )}
                </div>
                <div className="col-sm-6 col-lg-3 mb-3 ">
                  {loaders ? (
                    loader
                  ) : (
                    <span
                      className="card card-hover-shadow h-100"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className="card-body">
                        <h6 className="card-subtitle">ALLTIME</h6>
                        <div className="row align-items-center gx-2 mb-1">
                          <div className="col-6">
                            <h2 className="card-title text-inherit">
                              {userOrdersStats.allTime}
                            </h2>
                          </div>
                          <div className="col-6">
                            <div
                              className="chartjs-custom"
                              style={{ height: "3rem" }}
                            >
                              <div className="w-100 text-end display-4">
                                <i class="bi bi-cart-check-fill"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 ">
            <div className="card card-bordered h-100">
              <div className="card-header">
                <h5 className="card-title">Orders Graph</h5>
              </div>
              <div className="card-body">
                <div id="order_chart"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">Orders</h5>
              <div className="btn-group align-items-center gap-2">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    maxWidth: "200px",
                  }}
                  placeholder="Search..."
                  onChange={(e) => {
                    fetchIncomings(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">LabelType</th>
                    <th scope="col">Tracking</th>
                    <th scope="col">Price</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created At</th>
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>

                <tbody>
                  {products.length === 0 ? (
                    <tr>
                      <td colSpan="12" className="text-center">
                        <span className="text-center">No Orders</span>
                      </td>
                    </tr>
                  ) : (
                    products?.map((product) => (
                      <tr key={product._id}>
                        <td>{product._id}</td>
                        <td>{product.FromName}</td>
                        <td>{product.ToName}</td>
                        <td>{product.labelType?.name}</td>
                        <td>{product.tracking}</td>
                        <td>${product.price}</td>
                        <td>
                          <h4>
                            {product.status === "paid" && (
                              <Badge bg="warning">Paid</Badge>
                            )}
                            {product.status === "completed" && (
                              <Badge bg="success">Completed</Badge>
                            )}
                            {product.status === "cancelled" && (
                              <Badge bg="danger">Cancelled</Badge>
                            )}

                            {product.status === "pending" && (
                              <Badge bg="warning">Pending</Badge>
                            )}
                          </h4>
                        </td>
                        <td>
                          {" "}
                          {moment(new Date(product.createdAt)).format(
                            "M/D/YYYY, h:mm:ss A"
                          )}
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <button
                              className="btn btn-sm btn-info mr-2"
                              onClick={() => {
                                setOrder(product);
                                onOpenOrderDetailsModal();
                              }}
                            >
                              <i className="bi-eye"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                  {loaders && (
                    <tr>
                      <td colSpan="12" className="text-center">
                        <div className="text-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <nav>
                <ul className="pagination">
                  <li
                    className={page === 1 ? "page-item disabled" : "page-item"}
                  >
                    <Link
                      className="page-link"
                      to=""
                      onClick={(e) => {
                        setPage(page - 1);
                      }}
                    >
                      Prev
                    </Link>
                  </li>

                  <li className="page-item active" aria-current="page">
                    <Link className="page-link" to="">
                      {page}
                    </Link>
                  </li>

                  <li
                    className={
                      page === totalPages ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      to=""
                      tabIndex={-1}
                      aria-disabled={page === totalPages ? true : false}
                      onClick={(e) => {
                        setPage(page + 1);
                      }}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">Logs</h5>
              <div
                className="d-flex align-items-center text-center gap-2"
                style={{ whiteSpace: "nowrap" }}
              >
                <h4>Total Balance Added :</h4>
                <h4>
                  <Badge bg="success">
                    {userData?.totalBalanceAdded?.toFixed(2)}
                  </Badge>
                </h4>
              </div>
            </div>
          </div>

          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">DATE AND TIME</th>
                    <th scope="col">TYPE</th>
                    <th scope="col">DESCRIPTION</th>
                    <th scope="col">AMOUNT</th>
                    <th scope="col">BALANCE</th>
                    {/* <th scope="col">Created At</th> */}
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>

                <tbody>
                  {logsData.length === 0 ? (
                    <tr>
                      <td colSpan="12" className="text-center">
                        <span className="text-center">No Orders</span>
                      </td>
                    </tr>
                  ) : (
                    logsData?.map((product, i) => (
                      <tr key={product._id}>
                        <td>{i + 1}</td>
                        <td>{product.createdAt}</td>
                        <td>{product.type}</td>
                        <td>{product.action}</td>

                        <td>
                          <h4>
                            {product.amount > 0 && (
                              <Badge bg="success">
                                +${product?.amount?.toFixed(2)}
                              </Badge>
                            )}
                            {product.amount < 0 && (
                              <Badge bg="danger">
                                -${product?.amount?.toFixed(2)}
                              </Badge>
                            )}
                          </h4>
                        </td>
                        <td>${product?.balance?.toFixed(2)}</td>
                        {/* <td>
                          <div className="d-flex gap-2">
                            <button
                              className="btn btn-sm btn-info mr-2"
                              onClick={() => {
                                setOrder(product);
                                onOpenOrderDetailsModal();
                              }}
                            >
                              <i className="bi-eye"></i>
                            </button>
                          </div>
                        </td> */}
                      </tr>
                    ))
                  )}
                  {loaders && (
                    <tr>
                      <td colSpan="12" className="text-center">
                        <div className="text-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <nav>
                <ul className="pagination">
                  <li
                    className={
                      pageLog === 1 ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      to=""
                      onClick={(e) => {
                        setPageLog(pageLog - 1);
                      }}
                    >
                      Prev
                    </Link>
                  </li>

                  <li className="page-item active" aria-current="page">
                    <Link className="page-link" to="">
                      {pageLog}
                    </Link>
                  </li>

                  <li
                    className={
                      pageLog === totalPagesLog
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      to=""
                      tabIndex={-1}
                      aria-disabled={pageLog === totalPagesLog ? true : false}
                      onClick={(e) => {
                        setPageLog(pageLog + 1);
                      }}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {showOrderDetails ? (
          <ViewDetails
            isOpen={showOrderDetails}
            onClose={onCloseOrderDetailsModal}
            details={order}
          />
        ) : null}
      </>
    </div>
  );
};

export default Orders;
