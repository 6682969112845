import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { config, server } from "../env";
import { toast } from "react-toastify";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/v1/admin/invoice/read");

  useEffect(() => {
    getInvoices();
  }, [page, sort, filter, limit]);

  const getInvoices = async (search) => {
    var link = "";

    if (search) {
      link =
        url +
        "?page=" +
        page +
        "&limit=" +
        limit +
        "&sort=" +
        sort +
        "&status=" +
        filter +
        "&search=" +
        search;
    } else {
      link =
        url +
        "?page=" +
        page +
        "&limit=" +
        limit +
        "&sort=" +
        sort +
        "&status=" +
        filter;
    }

    await axios
      .get(server + link, config)
      .then((res) => {
        setInvoices(res.data.invoices);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markasPaid = async (id) => {
    await axios
      .put(server + "/v1/admin/invoice/markaspaid/" + id, {}, config)
      .then((res) => {
        getInvoices();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Deleteinvoice = async (id) => {
    await axios
      .delete(server + "/v1/admin/invoice/delete/" + id, config)
      .then((res) => {
        getInvoices();
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="content container-fluid">
      <Header title="Invoices" />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Invoices</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-4">
                          <div className="d-md-flex justify-content-between align-items-center">
                            <div className="d-md-flex justify-content-center align-items-center ">
                              <div className="form-group ml-2 mt-3">
                                <select
                                  name="sort"
                                  id=""
                                  className="form-control"
                                  value={sort}
                                  onChange={(e) => {
                                    setSort(e.target.value);
                                  }}
                                >
                                  <option value="asc">
                                    Sort by Date Ascending
                                  </option>
                                  <option value="des">
                                    Sort by Date Descending
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="d-md-flex justify-content-center align-items-center">
                              {/* .search */}
                              <div className="col mt-3">
                                <div className="form-group mr-3 ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    onChange={(e) => {
                                      getInvoices(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th scope="col">ID</th>
                                  <th scope="col">Username</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col">Payment Method</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Created At</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoices.map((invoice) => (
                                  <tr key={invoice._id}>
                                    <th scope="row">{invoice._id}</th>
                                    <td>{invoice.user.username}</td>
                                    <td>{"$" + invoice.amount.toFixed(2)}</td>
                                    <td>{invoice.payment_method}</td>

                                    <td>
                                      {invoice.status === "pending" ? (
                                        <Badge
                                          pill
                                          bg="secondary"
                                          text="dark"
                                          className="text-capitalize"
                                        >
                                          {invoice.status}
                                        </Badge>
                                      ) : invoice.status === "paid" ? (
                                        <Badge
                                          pill
                                          bg="success"
                                          className="text-capitalize"
                                        >
                                          {invoice.status}
                                        </Badge>
                                      ) : (
                                        <Badge
                                          pill
                                          bg="danger"
                                          className="text-capitalize"
                                        >
                                          {invoice.status}
                                        </Badge>
                                      )}
                                    </td>
                                    <td>
                                      {new Date(
                                        invoice.createdAt
                                      ).toLocaleString()}
                                    </td>
                                    <td>
                                      <div className="d-flex gap-2">
                                        <Link to={invoice.hosted_url}>
                                          <button className="btn btn-sm btn-info mr-2  px-2 py-1">
                                            <i class="bi bi-eye-fill"></i>
                                          </button>
                                        </Link>

                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              <b>Delete Invoice</b>
                                            </Tooltip>
                                          }
                                        >
                                          <button
                                            className="btn btn-sm btn-danger  px-2 py-1"
                                            onClick={() => {
                                              Deleteinvoice(invoice._id);
                                            }}
                                          >
                                            <i class="bi bi-trash-fill"></i>
                                          </button>
                                        </OverlayTrigger>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}
                                  >
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <Link className="page-link" to="">
                                    {page}
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
