import React, { Fragment, useEffect, useState } from "react";

import Header from "../components/Header";
import Loader from "../components/Loader";
import axios from "axios";
import { config, server } from "../env";
import Alert from "../components/Alert";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

const Users = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState("");
  const [alert, setAlert] = useState("");
  const [loaders, setLoaders] = useState(false);
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [url, setUrl] = useState("/v1/admin/users/read");
  const [total, setTotal] = useState(0);
  const [user, setUser] = useState({});
  const [showAddBalance, setShowAddBalance] = useState(false);
  const navigate = useNavigate();
  const onCloseAddBalanceModal = () => {
    setShowAddBalance(false);
  };
  const onOpenAddBalanceModal = () => setShowAddBalance(true);

  const [showRemoveBalance, setShowRemoveBalance] = useState(false);
  const onCloseRemoveBalanceModal = () => {
    setShowRemoveBalance(false);
  };
  const onOpenRemoveBalanceModal = () => setShowRemoveBalance(true);

  const [showUser, setShowUser] = useState(false);
  const onCloseUserModal = () => {
    setShowUser(false);
  };
  const onOpenUserModal = () => setShowUser(true);
  useEffect(() => {
    fetchIncomings();
  }, [page, limit]);

  const fetchIncomings = async (search) => {
    var link = "";
    if (search) {
      link = url + "?page=" + page + "&limit=" + limit + "&search=" + search;
    } else {
      link = url + "?page=" + page + "&limit=" + limit;
    }
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .get(server + link, config)
      .then((res) => {
        setProducts(res.data.users);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        setLoader("");
        setLoaders(false);
      });
  };

  const createAPIkey = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    setLoaders(true);
    const params = {
      username,
      password,
    };
    await axios
      .post(server + "/v1/admin/users/create", params, config)
      .then((res) => {
        fetchIncomings(res.data);
        toast.success(res.data.message);
        setUsername("");
        setPassword("");
        setLoader("");
        setLoaders(false);
        onCloseUserModal();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setLoader("");
        setLoaders(false);
        setUsername("");
        setPassword("");
        onCloseUserModal();
      });
  };

  const disabledAPIkey = async (id) => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .put(server + "/v1/admin/disable-user/" + id, {}, config)
      .then((res) => {
        fetchIncomings(res.data);
        toast.success(res.data.message);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);

        setLoader("");
        setLoaders(false);
      });
  };
  const deleteAPIkey = async (id) => {
    setLoader(<Loader />);
    setLoaders(true);
    await axios
      .delete(server + "/v1/admin/users/delete/" + id, config)
      .then((res) => {
        fetchIncomings(res.data);
        toast.success(res.data.message);
        setLoader("");
        setLoaders(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);

        setLoader("");
        setLoaders(false);
      });
  };

  const addBalance = async (e) => {
    e.preventDefault();

    const data = {
      balance: e.target.balance.value,
    };

    await axios
      .post(server + "/v1/admin/users/add-balance/" + user._id, data, config)
      .then((res) => {
        setLoader("");
        fetchIncomings();
        toast.success(res.data.message);
        onCloseAddBalanceModal();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const subBalance = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      balance: e.target.balance.value,
    };

    await axios
      .post(server + "/v1/admin/users/sub-balance/" + user._id, data, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        fetchIncomings();
        onCloseRemoveBalanceModal();
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });
  };

  const enableCustomPricing = async (id) => {
    await axios
      .put(server + "/v1/admin/custom-pricing/enable/" + id, {}, config)
      .then((res) => {
        fetchIncomings();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.danger(err.response?.data?.message);
      });
  };

  return (
    <div className="content container-fluid">
      {/* Page Header */}
      <Header title="Users" />

      <div className="d-flex justify-content-end align-items-center w-100 mb-3">
        <button
          className="btn btn-primary rounded-3"
          onClick={() => {
            onOpenUserModal();
          }}
        >
          <i className="bi-plus"></i>
          Add New User
        </button>
      </div>

      <>
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="card-title">All Users</h5>
              <div className="btn-group align-items-center gap-2">
                <input
                  type="text"
                  className="form-control"
                  style={{
                    maxWidth: "200px",
                  }}
                  placeholder="Search..."
                  onChange={(e) => {
                    fetchIncomings(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="card-body px-0 pb-0">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Username</th>
                    {/* <th>API KEY</th> */}
                    <th>Balance</th>
                    <th>Total Orders</th>
                    <th>Total Spends </th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {products?.map((product) => (
                    <tr key={product._id}>
                      <td>{product.username}</td>
                      {/* <td>{product.api_key}</td> */}
                      <td>${product.balance}</td>
                      <td>{product.orders}</td>
                      <td>${product.spends}</td>
                      <td>
                        {" "}
                        {product.status ? (
                          <Badge bg="success">Active</Badge>
                        ) : (
                          <Badge bg="danger">Inactive</Badge>
                        )}
                      </td>
                      <td>{new Date(product.createdAt).toDateString()}</td>
                      <td>
                        <div className="d-flex gap-2">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                <b>view Orders</b>
                              </Tooltip>
                            }
                          >
                            <Link
                              to={`/orders/${product._id}`}
                              className="btn btn-sm btn-info px-2 py-1"
                            >
                              <i className="bi-eye"></i>
                            </Link>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                <b>Add Balance</b>
                              </Tooltip>
                            }
                          >
                            <button
                              className="btn btn-sm btn-success  px-2 py-1"
                              onClick={() => {
                                setUser(product);
                                onOpenAddBalanceModal();
                              }}
                            >
                              <i class="bi bi-cash-coin"></i>
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                <b>Remove Balance</b>
                              </Tooltip>
                            }
                          >
                            <button
                              className="btn btn-sm btn-danger  px-2 py-1"
                              onClick={() => {
                                setUser(product);
                                onOpenRemoveBalanceModal();
                              }}
                            >
                              <i class="bi bi-cash-coin"></i>
                            </button>
                          </OverlayTrigger>
                          {product.status ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <b>Disable User</b>
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-sm btn-danger  px-2 py-1"
                                onClick={() => {
                                  disabledAPIkey(product._id);
                                }}
                              >
                                <i className="bi-person-fill-slash"></i>
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <b>Enable User</b>
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-sm btn-success px-2 py-1"
                                onClick={() => {
                                  disabledAPIkey(product._id);
                                }}
                              >
                                <i className="bi-person-check-fill"></i>
                              </button>
                            </OverlayTrigger>
                          )}

                          {product.customPricingEnabled ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <b>Disable Custom Pricing</b>
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-sm btn-danger  px-2 py-1"
                                onClick={() => {
                                  enableCustomPricing(product._id);
                                }}
                              >
                                <i class="bi bi-bookmark-x"></i>
                              </button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <b>Enable Custom Pricing</b>
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-sm btn-success px-2 py-1"
                                onClick={() => {
                                  enableCustomPricing(product._id);
                                }}
                              >
                                <i class="bi bi-bookmark-check"></i>
                              </button>
                            </OverlayTrigger>
                          )}
                          {product.customPricingEnabled && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  <b>Custom Pricing</b>
                                </Tooltip>
                              }
                            >
                              <button
                                className="btn btn-sm btn-warning  px-2 py-1"
                                onClick={() => {
                                  navigate(`/custom-pricing/${product._id}`);
                                }}
                              >
                                <i class="bi bi-cash-coin"></i>
                              </button>
                            </OverlayTrigger>
                          )}

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                <b>Delete User</b>
                              </Tooltip>
                            }
                          >
                            <button
                              className="btn btn-sm btn-danger  px-2 py-1"
                              onClick={() => {
                                deleteAPIkey(product._id);
                              }}
                            >
                              <i class="bi bi-trash"></i>
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {loaders && (
                    <tr>
                      <td colSpan="8">
                        <div className="text-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <nav>
                <ul className="pagination">
                  <li
                    className={page === 1 ? "page-item disabled" : "page-item"}
                  >
                    <Link
                      className="page-link"
                      to=""
                      onClick={(e) => {
                        setPage(page - 1);
                      }}
                    >
                      Prev
                    </Link>
                  </li>

                  <li className="page-item active" aria-current="page">
                    <Link className="page-link" to="">
                      {page}
                    </Link>
                  </li>

                  <li
                    className={
                      page === totalPages ? "page-item disabled" : "page-item"
                    }
                  >
                    <Link
                      className="page-link"
                      to=""
                      tabIndex={-1}
                      aria-disabled={page === totalPages ? true : false}
                      onClick={(e) => {
                        setPage(page + 1);
                      }}
                    >
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </>

      <Modal
        show={showUser}
        onHide={onCloseUserModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3>Add New User </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row text-center" onSubmit={createAPIkey}>
            <div className="col-12">
              <input
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter Username"
                className="form-control"
                required
              />
            </div>
            <div className="col-12 mt-2">
              <input
                type="text"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                className="form-control"
                required
              />
            </div>
            <div className="col-12 d-flex justify-content-start mt-3">
              <button className="btn btn-success btn-md" type="submit">
                {loaders ? loader : "Generate"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Add Balance */}
      <Modal
        show={showAddBalance}
        onHide={onCloseAddBalanceModal}
        backdrop="static"
        keyboard={false}
        s
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3>Add Balance </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addBalance} className="form-validate is-alter">
            <div className="form-group">
              <label className="form-label" htmlFor="full-name">
                Balance
              </label>

              <input
                type="number"
                name="balance"
                placeholder="Enter the amount to be added in user's balance"
                className="form-control"
              />
            </div>

            <div className="form-group mt-2 mb-2">
              <button className="btn btn-lg btn-primary" type="submit">
                Add {loader}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Remove Balance */}
      <Modal
        show={showRemoveBalance}
        onHide={onCloseRemoveBalanceModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3>Subtract Balance </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={subBalance} className="form-validate is-alter">
            <div className="form-group">
              <label className="form-label" htmlFor="full-name">
                Balance
              </label>

              <input
                type="number"
                name="balance"
                placeholder="Enter the amount to be added in user's balance"
                className="form-control"
              />
            </div>

            <div className="form-group mt-2 mb-2">
              <button className="btn btn-lg btn-danger" type="submit">
                Subtract {loader}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Users;
